<template>
   <el-container>
      <el-main>
         <el-row type="flex" justify="center">
            <el-image :src="require('@/assets/logo.svg')" class="logo"></el-image>
         </el-row>
         <el-row :gutter="12" type="flex" justify="center">
            <el-col :span="6" class="form">
               <el-card class="box-card signup">
                  <template #header>
                     <div class="card-header">
                        <span>Регистрация</span>
                     </div>
                  </template>
                  <el-form :model="signupForm" :rules="rules" ref="signupForm">
                        <el-form-item prop="name">
                              <el-input
                                 type="text"
                                 v-model.trim="signupForm.name"
                                 placeholder="Имя"
                              ></el-input>
                        </el-form-item>
                        <el-form-item prop="email">
                              <el-input
                                 type="text"
                                 v-model.trim="signupForm.email"
                                 placeholder="E-mail"
                              ></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                              <el-input
                                 type="password"
                                 v-model.trim="signupForm.password"
                                 placeholder="Пароль"
                              ></el-input>
                        </el-form-item>
                        <el-form-item>
                              <el-button @click="onSignup()">Регистрация</el-button>
                        </el-form-item>
                        <el-link>
                           <router-link :underline="false" to="/login">Вернуться к логину</router-link>
                        </el-link>
                  </el-form>
               </el-card>
            </el-col>
         </el-row>
      </el-main>
   </el-container>
</template>
<script>

import { ElMessage } from 'element-plus';
import { mapActions, mapMutations } from 'vuex';
import {
    LOADING_SPINNER_SHOW_MUTATION,
    SIGNUP_ACTION,
} from '../store/storeconstants';
export default {
    data() {
        return {
            errors: [],
            error: '',
            signupForm: {
               name: '',
               email: '',
               password: '',
            },
            rules: {
               name: [
                  { required: true, message: 'Поле имя не может быть пустым', trigger: 'blur' }
               ],
               email: [
                  { required: true, message: 'Поле email не может быть пустым', trigger: 'blur' },
                  { type: 'email', message: 'Email адрес не корректный', trigger: ['blur', 'change'] }
               ],
               password: [
                  { required: true, message: 'Поле пароль не может быть пустым', trigger: 'blur' },
                  { min: 6, message: 'Длина пароля минимум 6 символов', trigger: 'blur' }
               ]
            }
        };
    },
    methods: {
        ...mapActions('auth', {
            signup: SIGNUP_ACTION,
        }),

        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        async onSignup() {
            //make spinner true
            // this.showLoading(true);
            //signup registration
            try {
               await this.signup({
                  name: this.signupForm.name,
                  email: this.signupForm.email,
                  password: this.signupForm.password,
                })

               await ElMessage({
                  type: "success",
                  message: "Вы успешно зарегистрировались",
               })

               await ElMessage({
                  type: "success",
                  message: "Войдите в личный кабинет",
               })

               this.$router.push('/login');

            } catch (error) {
               this.error = error;

               await ElMessage({
                  type: 'warning',
                  message: `${this.error}`,
               });

               this.$router.push('/signup');
            }

            this.showLoading(false);
            //make spinner false
        },
    },
};
</script>

<style>
   .logo {
      margin: 15px 0px 35px;
   }
</style>

<style scoped>
   .el-container {
         background: #5E597B;
         height: 100%;
      }
   .el-button {
      width: 100%;
   }
   a {
      text-decoration: none;
   }
   .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
</style>
